<template>
  <div id="app-container" class="row align-items-center justify-content-center">
    <div class="terminal-bg">
      <section id="terminal">
        <router-view />
      </section>
    </div>
    <div class="text">
      <h2>
        <a href="https://donateo.pl/" target="_blank"
          ><img
            class="donateo-logo"
            :src="require('@/assets/img/logo-donateo.svg')" /></a
        ><br /><span>#SercemzUkrainą!</span>
      </h2>
      <h1>Nieśmy wspólnie pociechę dzieciom z Ukrainy!</h1>
      <p>
        Dzięki Tobie, dzieci uciekające z Ukrainy wraz z mamami, mogą znaleźć
        bezpieczne schronienie w ośrodkach wspieranych przez Fundację Pociecha.
      </p>
      <p>
        Dzięki Twojej darowiźnie zapewnimy im w naszych czterech ośrodkach
        najpilniejszą pomoc:
      </p>
      <ul>
        <li>wsparcie psychologa</li>
        <li>kontynuację edukacji</li>
        <li>pomoc animatora dla dzieci.</li>
      </ul>
      <p>
        Twoja wpłata trafi bezpośrednio na konto<br />
        <strong>Fundacji Pociecha</strong>
      </p>
      <p>Dziękujemy za każdy gest w ich imieniu!</p>
      <img :src="require('@/assets/img/logo.png')" alt="" />
    </div>
    <div class="mobile-text" v-if="showText">
      <div>
        <img :src="require('@/assets/img/logo.png')" alt="" />
      </div>
      <h2>
        <a href="https://donateo.pl/" target="_blank"
          ><img
            class="donateo-logo"
            :src="require('@/assets/img/logo-donateo.svg')" /></a
        ><br /><span>#SercemzUkrainą!</span>
      </h2>
      <h1>Nieśmy wspólnie pociechę dzieciom z Ukrainy!</h1>
      <p>
        Dzięki Tobie, dzieci uciekające z Ukrainy wraz z mamami, mogą znaleźć
        bezpieczne schronienie w ośrodkach wspieranych przez Fundację Pociecha.
      </p>
      <p>
        Dzięki Twojej darowiźnie zapewnimy im w naszych czterech ośrodkach
        najpilniejszą pomoc:
      </p>
      <ul>
        <li>wsparcie psychologa</li>
        <li>kontynuację edukacji</li>
        <li>pomoc animatora dla dzieci.</li>
      </ul>
      <p>
        Twoja wpłata trafi bezpośrednio na konto<br />
        <strong>Fundacji Pociecha</strong>
      </p>
      <p>Dziękujemy za każdy gest w ich imieniu!</p>
    </div>
    <div class="ibtn" v-on:click="showText = !showText">
      <img :src="require('@/assets/img/i_btn.png')" alt="" v-if="!showText" />
      <img :src="require('@/assets/img/x_btn.png')" alt="" v-if="showText" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showText: false,
    };
  },
};
</script>

<style lang="scss">
@import "./assets/styles/App.css";
</style>
